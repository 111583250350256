<template>
  <base-modal
    modal-title="Change Contract"
    @visible="fetchSubmissionContracts()"
    @hidden="reset()"
  >
    <template #modalBody>

      <div class="flex justify-center p-4" v-if="submissionContractsLoading">
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
      </div>

      <div class="px-4 py-3" v-else>
        <div v-if="!fetchedSubmissionContracts.length" class="p-4 mb-2 w-full text-center">
          No replacement Contracts are available for the given submission.
        </div>
        <base-select
          v-else
          v-model="selectedContract"
          class="flex items-center text-sm text-left mb-6"
          :placeholder="`${submissionForm.contractNumber} - Status: ${submissionForm.contractStatus}`"
          label="Contract Number"
          label-class="label-w-46"
          :options="fetchedSubmissionContracts.map(e => `${e.contract_number} - Status: ${e.contract_status}`)"
          :disable="!fetchedSubmissionContracts.length"
          show-default-option
        />

        <div class="text-center">
          <base-button
            class="mr-3"
            variant="btn-primary"
            :disabled="!updateContractBtnActive"
            text="Change"
            @click="updateContract()"
          />
          <base-button
            variant="btn-link"
            text="Cancel"
            @click="handleCancel()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';

export default {
    name: 'ChangeContractModal',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const { submissionForm, submissionFormMode, updateSubmissionForm } = useSubmissionForm();

        const fetchedSubmissionContracts = ref([]);
        const submissionContractsLoading = ref(false);
        const fetchSubmissionContracts = async () => {
            try {
                if (submissionContractsLoading.value) {
                    return;
                }

                let params = { licenseeId: submissionForm.licenseeId }
                if (!['DRAFT','NEW'].includes(submissionFormMode.value)) {
                  // params.contractNumber = submissionForm.contractNumber
                  //params.articleId = submissionForm.article
                  params.styleGuideId = submissionForm.styleGuideId
                }

                submissionContractsLoading.value = true;
                fetchedSubmissionContracts.value = await store.dispatch('baSubmissions/fetchSubmissionContracts', {
                  params
                });
            } catch (err) {
                console.error(err);
            } finally {
                submissionContractsLoading.value = false;
            }
        };

        const selectedContract = ref('');
        const updateContractBtnActive = computed(() =>
            selectedContract.value && `${selectedContract.value.split(' ')[0]}` != submissionForm.contractNumber
        );

        // update contract logic
        const updateContract = async () => {
            try {
                let tmp = fetchedSubmissionContracts.value.find(e => e.contract_number == selectedContract.value.split(' ')[0])

                await fetchArticles(tmp?.contract_number);
                const fetchedArticleDetails = computed(() => store.getters['baLicensee/getArticleContractList']);
                const newCategory = fetchedArticleDetails.value?.data.length > 0 ? fetchedArticleDetails.value.data.find(id => submissionForm && submissionForm.category === id.articleCategory ? id.articleCategory : '') : '';
                const newArticle = fetchedArticleDetails.value?.data.length > 0 ? fetchedArticleDetails.value.data.find(id => submissionForm && submissionForm.article === id.articleId ? id.articleId : '') : '';
                if (tmp) {
                    let formChanges = {
                        contractNumber: `${tmp.contract_number}`,
                        contractStatus: `${tmp.contract_status}`,
                        category: `${newCategory?.articleCategory || ''}`,
                        articleName: `${newArticle?.article || ''}`
                    };
                    if (['DRAFT', 'NEW'].includes(submissionFormMode.value)) {
                        formChanges = { ...formChanges, styleGuideName: '', styleGuideId: '' }
                    }
                    updateSubmissionForm(formChanges);
                }

                emit('hide');
            } catch (err) {
                console.error(err);
            }
        };

        const articleDetailsLoading = ref(false);
        const fetchArticles = async (contractNumber) => {
            try {
                articleDetailsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmArticlesDetails', {
                    params: {
                        articleCategory: submissionForm.category,
                        page: 1,
                        limit: 5000
                    },
                    id: contractNumber
                });
            } catch (err) {
                console.error(err);
            } finally {
                articleDetailsLoading.value = false;
            }
        };


        // cancel logic
        const handleCancel = () => {
            emit('hide');
        };
        const reset = () => {
            fetchedSubmissionContracts.value = []
            selectedContract.value = '';
        };


        return {
            fetchedSubmissionContracts,
            fetchSubmissionContracts,

            submissionContractsLoading,

            selectedContract,

            updateContract,
            updateContractBtnActive,

            submissionForm,
            submissionFormMode,

            handleCancel,
            reset
        };
    }
};
</script>
